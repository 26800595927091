import { colors, mediaQueries } from "@/theme/lib";
import styled from "styled-components";

export const StyledBlock = styled.div`
  color: ${(props) => props.theme.colors.donate.text};
  background: ${(props) => props.theme.colors.donate.background};
  width: 100%;
  max-width: 37.375rem;
  padding: 14px 18px;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  border-radius: 5px 5px 0px 0px;
  box-sizing: border-box;
  z-index: 1;
  ${mediaQueries.md} {
    padding: 1.875rem;
    gap: 1rem;
  }

  /* Common elements */
  .title {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    font-family: ${(props) => props.theme.fonts.heading};
    ${mediaQueries.md} {
      font-size: 2.2rem;
    }
  }

  .description {
    line-height: 145%;
    font-size: 18px;
  }

  .cta-wrapper {
     a {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: ${(props) => props.theme.colors.donate.link.text};
      background: ${(props) => props.theme.colors.donate.link.background};
      font-family: ${(props) => props.theme.fonts.button};
      text-transform: uppercase;
      font-size: 18px;
      padding: 0.75rem;
      font-weight: 700;
      ${mediaQueries.md} {
        padding: 1rem;
      }

      &.errors {
        cursor: not-allowed;
        opacity: 0.7;
      }
     }
     svg {
      position: absolute;
      right: 19px;
      top: 50%;
      transform: translateY(-50%);
     }
  }

  /* Donate multiple */
  button {
    padding: 12px 0;
    width: 100%;
    border-radius: 35px;
    border: ${(props) => props.theme.colors.donate.button.background};
    background: ${(props) => props.theme.colors.donate.button.background};
    color: ${(props) => props.theme.colors.donate.text};
    transition: all .3s ease;

    ${mediaQueries.md} {
      padding: 15px 0;
    }
    span {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      text-transform: uppercase;
      font-family: ${(props) => props.theme.fonts.button};
    }
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.colors.donate.button.hoverText};
    }
    &.active {
      padding: 12px;
      ${mediaQueries.md} {
        padding: 15px 0;
      }
      color: ${(props) => props.theme.colors.donate.button.background};
      background: ${(props) => props.theme.colors.donate.button.activeBackground};
    }
  }
  .buttons-wrapper {
    display: flex;
    flex-flow: row nowrap;
    background: var(--Panther, #002010);
    border-radius: 35px;
    padding: 2px;
    box-sizing: border-box;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }

  input.other-amount {
    width: 100%;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    padding: 15px 18px 11px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 2px solid ${(props) => props.theme.colors.donate.background};

        /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }

    &.errors {
      border-color: ${colors.redPanda};
    }
  }
  .other-validation-message-wrapper {
    display: inline-flex;
    position: relative;
  }
  .other-validation-message {
    color: ${colors.redPanda};
    font-style: italic;
    font-weight: bold;
    padding: 0.5rem;
    background: white;
    border-radius: 5px;
    animation: fadeIn 0.5s;
    z-index: 1;
    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
    &:after {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      position: absolute;
      transform: rotate(45deg);
      top: -0.5rem;
      background: ${colors.white};;
      left: 2rem;
      z-index: -1;
    }
  }
`;
