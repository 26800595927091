import { colors, container, eyebrow, grid, h1, mediaQueries } from '@/theme/lib';
import styled, { css } from 'styled-components';

const blackCircleGradient = css`
  position: relative;
  z-index: 1;
  ::before {
    content: '';
    display: ${(props) => (props.theme.key === 'zsl' ? 'block' : 'none')};
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    width: 150%;
    height: ${(props) => (props.theme.key === 'zsl' ? '150%' : '140%')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    ${mediaQueries.lg} {
      background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
      height: ${(props) => (props.theme.key === 'zsl' ? '200%' : '140%')};
    }
  }
`;

export const StyledHero = styled.div`
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;
  .hero {
    &__wrapper {
      ${container}
      ${grid}
      position: relative;
      grid-template-areas: 'box box box box box';
      ${mediaQueries.lg} {
        grid-template-areas: 'box box box box box . . . . .';
      }
      svg {
        display: none;
      }
    }
  }
  .hero__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    mask-image: ${(props) =>
      props.theme.key !== 'zsl' ? 'url(/Hero/bgMask-mobile.svg)' : undefined};
    mask-repeat: ${(props) => (props.theme.key !== 'zsl' ? 'no-repeat' : undefined)};
    mask-position: ${(props) => (props.theme.key !== 'zsl' ? 'bottom' : undefined)};
    ${mediaQueries.smHero} {
      mask-image: ${(props) =>
        props.theme.key !== 'zsl' ? 'url(/Hero/bgMask-desktop.svg)' : undefined};
    }

    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: ${colors.black};
      opacity: ${(props) => (props.theme.key === 'zsl' ? 0 : 0.3)};
    }
  }
`;

export const StyledCircleImage = styled.div`
  display: none;
  ${mediaQueries.lg} {
    grid-area: circle;
    position: relative;
    display: block;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 653.5px;
    width: 653.5px;
    margin-top: 23px;
    border-radius: 50%;
    z-index: 9;
    &:after {
      content: '';
      position: absolute;
      top: -20px;
      left: -20px;
      height: 100%;
      width: 100%;
      border: 5px solid ${colors.tiger};
      border-radius: 50%;
      height: 683.5px;
      width: 683.5px;
    }
    img {
      border-radius: 50%;
    }
  }
`;

export const StyledHeroBox = styled.div`
  word-break: normal;
  overflow-wrap: break-word;
  grid-area: box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 0px 0px 12px;
  background: transparent;
  z-index: 99;

  ${mediaQueries.mdLogo} {
    padding-top: 5rem;
    padding-left: 1rem;
  }
  ${mediaQueries.lg} {
    max-width: 824px;
    gap: 16px;
    padding: 0px 0px 16px;
    margin-top: 6.5rem;
  }
  span {
    ${eyebrow}
    text-transform: initial;
    display: block;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: ${(props) => (props.theme.key === 'zsl' ? colors.parakeet : props.theme.colors.eyebrow)};
    font-size: 16px;
    font-family: ${(props) => props.theme.fonts.body};
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 0.5rem;
    ${mediaQueries.lg} {
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 1rem;
    }
  }
  h1 {
    ${h1}
    flex: none;
    order: 1;
    flex-grow: 0;
    color: ${(props) => (props.theme.key === 'zsl' ? colors.white : colors.zooFont)};
    font-size: 40px;
    line-height: 42px;
    text-transform: ${(props) => (props.theme.key === 'zsl' ? 'uppercase' : undefined)};
    font-weight: ${(props) => (props.theme.key === 'zsl' ? '600' : '800')};
    ${mediaQueries.lg} {
      font-weight: ${(props) => (props.theme.key === 'zsl' ? undefined : '600')};
      font-size: 80px;
      line-height: 84px;
    }
  }
  .hero__cta-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin-top: 0.5rem;
    ${mediaQueries.lg} {
      gap: 16px;
      margin-top: 1rem;
    }
  }
`;

export const StyledTextOnlyHero = styled(StyledHero)`
  position: relative;
  overflow: hidden;
  .hero {
    &__wrapper {
      position: static;
      &:after {
        display: ${(props) => (props.theme.key === 'zsl' ? 'block' : 'none')};
        position: absolute;
        content: '';
        // Todo: get asset from proper place/handle differently
        background-image: url(/Hero/ellipse.svg);
        background-size: cover;
        background-repeat: no-repeat;

        background-position: bottom right;
        bottom: 0px;
        height: 100%;
        width: 100%;
        left: 0px;
        ${mediaQueries.mdLogo} {
          top: -5%;
        }
        ${mediaQueries.lg} {
          top: 0;
          bottom: 0px;
          background-position: 50% 100%;
          height: 100%;
          width: 100%;
          left: -15%;
        }
        ${mediaQueries.xl} {
          left: -5%;
        }
      }
      svg {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        ${mediaQueries.lg} {
          height: 100%;
          width: 50%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    &__box {
      height: fit-content;
      align-self: center;
      margin-bottom: -0.75rem;
      padding: 9.063rem 0 0 0;
      ${mediaQueries.mdLogo} {
        padding: 11.625rem 0 0;
        margin-bottom: 0;
      }
      ${mediaQueries.lg} {
        padding-bottom: 1rem;
      }
    }
    &__inner-wrapper {
      background-color: ${(props) => props.theme.colors.background};
      padding: 0.75rem;
      margin-left: -0.75rem;
      border-radius: 5%;
      max-width: 100%;

      ${mediaQueries.lg} {
        padding: 1rem 1rem 2rem 1rem;
        margin-left: -1rem;
        max-width: none;
      }
    }
  }
`;

export const StyledShortHero = styled(StyledHero)`
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .hero {
    &__wrapper {
      height: 420px;
      ${mediaQueries.lg} {
        height: 729px;
      }
    }
    &__box {
      align-self: end;
      margin-top: 8.5rem;
      margin-bottom: 2rem;
      ${mediaQueries.lg} {
        align-self: center;
      }
    }
    &__inner-wrapper {
      ${blackCircleGradient}
      max-width: 100%;
      ${mediaQueries.lg} {
        max-width: none;
      }
      span,
      h1 {
        text-shadow: ${(props) =>
          props.theme.key === 'zsl' ? '0px 0px 10px rgba(0, 0, 0, 1)' : undefined};
      }
      h1 {
        color: ${colors.white};
      }
      span {
        color: ${(props) => (props.theme.key !== 'zsl' ? colors.honeyBee : undefined)};
      }
    }
  }
`;

export const StyledCircleHero = styled(StyledHero)`
  ${mediaQueries.lg} {
    background-image: none;
  }
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .hero {
    &__image {
      ${mediaQueries.lg} {
        display: none;
      }
    }
    &__wrapper {
      height: 420px;
      grid-template-areas: 'box box box box box';
      h1,
      span {
        color: ${(props) => (props.theme.key === 'zsl' ? undefined : colors.white)};
        ${mediaQueries.lg} {
          color: ${(props) => (props.theme.key === 'zsl' ? undefined : colors.zooFont)};
        }

        text-shadow: ${(props) =>
          props.theme.key === 'zsl' ? '0px 0px 10px rgba(0, 0, 0, 1)' : undefined};
      }
      ${mediaQueries.lg} {
        height: 729px;
        grid-template-areas: 'box box box box box circle circle circle circle circle';
        h1,
        span {
          text-shadow: none;
        }
      }
    }
    &__box {
      align-self: end;
      margin-top: 8.5rem;
      margin-bottom: 2rem;
      ${mediaQueries.lg} {
        align-self: center;
      }
    }
    &__inner-wrapper {
      background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
      ${mediaQueries.lg} {
        background: none;
      }
    }
  }
`;

export const StyledVideoHero = styled(StyledHero)`
  position: relative;
  overflow: hidden;
  mask-image: ${(props) =>
    props.theme.key !== 'zsl' ? 'url(/Hero/bgMask-mobile.svg)' : undefined};
  mask-repeat: ${(props) => (props.theme.key !== 'zsl' ? 'no-repeat' : undefined)};
  mask-position: ${(props) => (props.theme.key !== 'zsl' ? 'bottom' : undefined)};
  ${mediaQueries.smHero} {
    mask-image: ${(props) =>
      props.theme.key !== 'zsl' ? 'url(/Hero/bgMask-desktop.svg)' : undefined};
  }

  /* max-height: 580px; */
  ${mediaQueries.lg} {
    max-height: 1029px;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    margin-top: 84px;
    ${mediaQueries.md} {
      margin-top: 0;
    }

    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: ${colors.black};
      opacity: ${(props) => (props.theme.key === 'zsl' ? 0 : 0.3)};
    }
    &--black + .hero__wrapper {
      .hero-primary {
        background: ${colors.white};
        border-color: ${colors.black};
        color: ${colors.black};
        &:focus,
        &:hover {
          background-color: ${colors.white60};
          color: ${colors.black60};
        }
      }
      .hero-secondary {
        background: ${colors.black};
        border-color: ${colors.white};
        color: ${colors.white};
        &:focus,
        &:hover {
          background-color: ${colors.black60};
          color: ${colors.white60};
        }
      }
    }
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .hero {
    &__wrapper {
      margin-top: 1rem;

      ${mediaQueries.md} {
        margin-top: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        h1,
        span {
          text-shadow: ${(props) =>
            props.theme.key === 'zsl' ? '0px 0px 10px rgba(0, 0, 0, 1)' : undefined};
        }
        h1 {
          color: ${colors.white};
        }
      }
      span {
        color: ${(props) => (props.theme.key === 'london' ? colors.seagrass : undefined)};
        color: ${(props) => (props.theme.key === 'whipsnade' ? colors.redPanda : undefined)};
        ${mediaQueries.md} {
          color: ${(props) => (props.theme.key !== 'zsl' ? colors.honeyBee : undefined)};
        }
      }
    }
    &__box {
      align-self: end;
      margin-bottom: 4rem;
      ${mediaQueries.lg} {
        align-self: center;
        margin-top: ${(props) => (props.theme.key === 'zsl' ? '8rem' : '3rem')};
        margin-bottom: 0;
      }
    }
    &__inner-wrapper {
      max-width: 100%;
      ${mediaQueries.md} {
        ${blackCircleGradient}
      }
      ${mediaQueries.lg} {
        max-width: none;
      }
    }
  }
`;

export const StyledTallHero = styled(StyledHero)`
  .hero {
    &__wrapper {
      height: 580px;
      ${mediaQueries.lg} {
        height: 1029px;
        max-height: 90vh;
        min-height: 50rem;
      }
    }
    &__box {
      align-self: end;
      margin-bottom: 4rem;
      ${mediaQueries.lg} {
        align-self: center;
        margin-top: ${(props) => (props.theme.key === 'zsl' ? '8rem' : '3rem')};
        margin-bottom: 0;
      }
    }
    &__inner-wrapper {
      ${blackCircleGradient}
      max-width: 100%;
      ${mediaQueries.lg} {
        max-width: none;
      }
      span,
      h1 {
        text-shadow: ${(props) =>
          props.theme.key === 'zsl' ? '0px 0px 10px rgba(0, 0, 0, 1)' : undefined};
      }
      h1 {
        color: ${colors.white};
      }
      span {
        color: ${(props) => (props.theme.key !== 'zsl' ? colors.honeyBee : undefined)};
      }
    }
  }

  position: relative;
  .swiper {
    width: 100%;
    height: 580px;
    ${mediaQueries.lg} {
      height: 1029px;
      max-height: 90vh;
      min-height: 50rem;
    }
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    > * {
      user-select: none;
      touch-action: manipulation;

      ${mediaQueries.md} {
        user-select: auto;
        touch-action: auto;
      }
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Slide controlleer */
  .swiper-pagination {
    display: none;
  }
`;

export const StyledSliderImg = styled.div`
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
`;

export const StyledSlideController = styled.div`
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  bottom: 1rem;
  ${mediaQueries.lg} {
    bottom: ${(props) => (props.theme.key === 'zsl' ? '54px' : '84px')};
  }
  left: 0;
  width: 100%;
  z-index: 10;
  .slide-controller {
    &__wrapper {
      ${container}
      ${grid}
      grid-template-areas: 'page page page page page';
      ${mediaQueries.lg} {
        grid-template-areas: 'page page page page page page page page page page';
      }
    }
    &__container {
      grid-area: page;
      display: flex;
      width: 100%;
      align-items: center;
    }
    &__play {
      cursor: pointer;
      margin-right: 16px;
      width: ${(props) => (props.theme.key === 'zsl' ? '44px' : '48px')};
      height: ${(props) => (props.theme.key === 'zsl' ? '44px' : '48px')};
      background: url(${(props) =>
        props.theme.key === 'zsl' ? '/Hero/Play-zsl.svg' : '/Hero/Play-zoos.svg'});
      background-repeat: no-repeat;
      background-position: center;
      ${mediaQueries.lg} {
        display: block;
      }

      &--active {
        background: url(${(props) =>
          props.theme.key === 'zsl' ? '/Hero/Pause-zsl.svg' : '/Hero/Pause-zoos.svg'});
      }
    }
    &__slide {
      cursor: pointer;
      height: 16px;
      width: 16px;
      border-radius: ${(props) => props.theme.config.buttonRadius};
      opacity: 1;
      background-color: ${(props) =>
        props.theme.key === 'zsl' ? colors.seagrassDark : colors.whipsnadeBg};
      border: ${(props) => (props.theme.key !== 'zsl' ? `1px solid transparent` : undefined)};
      margin-right: 8px;
      ${mediaQueries.lg} {
        margin-right: 12px;
        width: 24px;
        height: 24px;
      }
      &--active {
        background-color: ${(props) =>
          props.theme.key === 'zsl' ? colors.parakeet : props.theme.colors.button.primary.bg};
        border: ${(props) => (props.theme.key !== 'zsl' ? `1px solid ${colors.white}` : undefined)};
      }
    }
  }
`;

export const StyledCampaignHero = styled(StyledHero)`
  .hero {
    &__wrapper {
      height: 580px;
      ${mediaQueries.lg} {
        height: 1029px;
        max-height: 90vh;
        min-height: 50rem;
      }
    }
    &__box {
      align-self: end;
      margin-bottom: 4rem;
      ${mediaQueries.lg} {
        align-self: center;
        margin-top: ${(props) => (props.theme.key === 'zsl' ? '8rem' : '3rem')};
        margin-bottom: 0;
      }
    }
    &__inner-wrapper {
      ${blackCircleGradient}
      max-width: 100%;
      ${mediaQueries.lg} {
        max-width: none;
      }
      span,
      h1 {
        text-shadow: ${(props) =>
          props.theme.key === 'zsl' ? '0px 0px 10px rgba(0, 0, 0, 1)' : undefined};
      }
      h1 {
        color: ${colors.white};
      }
      span {
        color: ${(props) => (props.theme.key !== 'zsl' ? colors.honeyBee : undefined)};
      }
    }
  }
`;

export const StyledDonateHero = styled(StyledHero)`
  .hero {
    &__wrapper {
      min-height: 408px;
      ${mediaQueries.lg} {
        height: 1029px;
        max-height: 90vh;
        min-height: 50rem;
      }
    }
    &__box {
      align-self: end;
      ${mediaQueries.lg} {
        align-self: center;
        margin-top: ${(props) => (props.theme.key === 'zsl' ? '8rem' : '3rem')};
        margin-bottom: 0;
      }
    }
    &__inner-wrapper {
      ${blackCircleGradient}
      &::before {
        left: 20%;
      }
      max-width: 100%;
      ${mediaQueries.lg} {
        max-width: none;
      }
      span,
      h1 {
        text-shadow: ${(props) =>
          props.theme.key === 'zsl' ? '0px 0px 10px rgba(0, 0, 0, 1)' : undefined};
      }
      h1 {
        color: ${colors.white};
      }
      span {
        color: ${(props) => (props.theme.key !== 'zsl' ? colors.honeyBee : undefined)};
      }
    }
  }
`;

export const StyledDonationHeroWrapper = styled.div`
  &.blur {
    filter: blur(5px) brightness(75%);
  }
`;

export const StyledDonationWrapperInner = styled.div`
  display: none;
  right: 100px;
  width: 37.375rem;
  ${mediaQueries.lg} {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  bottom: 0;
  align-items: end;
  ${mediaQueries.navxl} {
  right: 196px;
  }
  }
`;

export const StyledDonationWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 3;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: -2rem;
  width: 100%;
  & > div {
    border-radius: 0;
  }
  ${mediaQueries.lg} {
    display: none;
  }
`;
